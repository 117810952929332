import React, { useState } from "react"
import PageWrapper from "../components/PageWrapper";
import Content from "../sections/exface/exfacecontent.js";
import Prprecovery from "../sections/exface/exfaceglance.js";
import Hero from "../sections/loss/losshero";
import Faq from "../sections/exface/exfacefaq.js";
import Package from "../sections/exface/exofacepackage.js";
import PRPgallery from "../sections/prp/prpgallery";
import Brand from "../sections/homepage/Brands.js";
import Head from "../components/head";
import { JsonLd } from '../components/JsonLd';
import Testimonial from "../sections/reviews/prpreviews.js";
import Pop from "../sections/landing/popular";
import Content2 from "../sections/exface/exfacecontent2.js";
import Saveface from "../sections/landing/saveface";
import Clinic from '../sections/nose/nosefind.js'; 
import MyImage from "../assets/image/jpeg/exo-face.jpg";
import TwoColumnLayout from '../shared/TwoColumnLayout.js';
import Award from "../sections/index/award.js";
import PopupForm from "../components/PopupForm/PopupForm"

const titleText = "Exosomes For Face";
  const textContent = "Unlock the Secrets to Healthier Looking Skin with Exosome";

const ExoLossPRPPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
            <Head title="Exosomes Face Treatment London" image="/images/dermamina-home.jpg"  description="Rejuvenate your skin with exosomes face treatment. Harness your body's natural healing for healthier face. Discover the benefits now!" keywords="exosomes face treatment, exosomes face therapy, Exosomes London, Face Rejuvenation treatment"/>

            <JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'Organization',
'@id': 'https://www.dermamina.com/#organization',
url: 'https://www.dermamina.com',
name: 'Dermamina',
contactPoint: {
  '@type': 'ContactPoint',
  telephone: '020 7099 6650',
  contactType: 'Customer service',
},
address:{
  '@type': 'PostalAddress',
streetAddress: '114 New Cavendish Street',
addressRegion:'London',
postalCode:'W1W 6XT',
addressLocality:'Westminster',
},
image: {
  '@type': 'ImageObject',
  url: 'https://dermamina.com/images/dermamina-home.jpg',   
},
logo: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-logo.svg',   
width: '200',
},
'name': 'dermamina',
'sameAs': [

'https://www.facebook.com/DermaMina-1985252831708400/',
'https://www.instagram.com/dermamina',
],
}}
</JsonLd>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'BreadcrumbList',
'itemListElement': [
  {'@type': 'ListItem', 
position:1,
name:'Dermamina Clinic',
item:'https://www.dermamina.com',
},

{'@type': 'ListItem', 
position:2,
name:'Non-Surgical Rhinoplasty',
item:'https://www.dermamina.com/non-surgical-nose-job',
},

{'@type': 'ListItem', 
position:3,
name:'PRP Hair Loss',
item:'https://www.dermamina.com/prp-for-hair-loss',
},

]
}}
</JsonLd>

      <PageWrapper headerDark>
        <PopupForm
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
   
      <TwoColumnLayout title={titleText} text={textContent} imageData={null} image={MyImage} />
      <Prprecovery />
<Content setIsModalOpen={setIsModalOpen}/>
<Content2
  setIsModalOpen={setIsModalOpen}
/>
<Package />
<Testimonial />
<Award />
<Faq
  setIsModalOpen={setIsModalOpen}
/>
<Pop />    
<Clinic /> 
<Brand />
<Saveface />

      </PageWrapper>
     
    </>
  );
};
export default ExoLossPRPPage;
